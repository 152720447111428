import { Injectable } from '@angular/core';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccountClient, AccountResultDto, TokenType } from '../api-client';
import { claims } from '../constants/claims.constants';
import { onError, unwrap } from '../extensions';
import { LocalStorageService } from './local-storage.service';
import { TrackingService } from './tracking.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface UserInfo {
  id: number;
  email: string;
  roles: string[];
}

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class JWTTokenService {
  get jwtToken(): string | null {
    return this.localStorage.get('access_token');
  }

  get decodedToken(): { [key: string]: string | string[] } {
    if (this.jwtToken) {
      return jwt_decode(this.jwtToken);
    } else {
      return {};
    }
  }

  get currentUser(): UserInfo | null {
    if (!this.jwtToken) {
      return null;
    }
    return {
      id: +this.decodedToken[claims.id],
      email: this.decodedToken[claims.email] as string,
      roles: this.decodedToken[claims.role] as string[],
    };
  }

  private _currentUser: BehaviorSubject<UserInfo | null> =
    new BehaviorSubject<UserInfo | null>(null);
  currentUser$: Observable<UserInfo | null> = this._currentUser.asObservable();

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly accountClient: AccountClient,
    private readonly tracking: TrackingService
  ) {
    this._currentUser.next(this.currentUser);

    this.currentUser$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (user) {
        this.tracking.setUser(user);
      } else {
        this.tracking.setUser(null);
      }
    });
  }

  setToken(token: string) {
    if (token) {
      this.localStorage.set('access_token', token);
    }
  }

  refreshToken() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return of();
    }

    return this.accountClient
      .refreshToken({
        refreshToken,
        tokenType: TokenType.Password,
      })
      .pipe(
        unwrap<AccountResultDto>(),
        onError(() => {
          this.localStorage.remove('access_token');
          this.localStorage.remove('refresh_token');
          this.localStorage.remove('refresh_token_expire_at');
          window.location.reload();
        }),
        tap((data) => this.setUserDetail(data))
      );
  }

  setUserDetail(result: AccountResultDto) {
    this.setToken(result.accessToken);
    if (result.refreshToken) {
      this.setRefreshToken(result.refreshToken, result.refreshTokenExpireAt);
    }
    this._currentUser.next(this.currentUser);
  }

  setRefreshToken(refreshToken: string, refreshTokenExpireAt?: Date) {
    this.localStorage.set('refresh_token', refreshToken);
    if (refreshTokenExpireAt) {
      this.localStorage.set(
        'refresh_token_expire_at',
        refreshTokenExpireAt.toString()
      );
    } else {
      this.localStorage.remove('refresh_token_expire_at');
    }
  }

  getRefreshToken() {
    return this.localStorage.get('refresh_token');
  }

  getToken(): string | null {
    return this.localStorage.get('access_token');
  }

  isTokenAvailable(): boolean {
    // eslint-disable-next-line prefer-const
    let token = this.localStorage.get('access_token') ?? '';
    return token !== '';
  }

  getUserId(): number {
    return +this.decodedToken[claims.id];
  }

  getEmailId(): string {
    return this.decodedToken[claims.email] as string;
  }

  getRoles() {
    return this.decodedToken[claims.role];
  }

  getCompanyId() {
    return this.decodedToken.CompanyId;
  }

  getEmailConfirmed(): boolean {
    return this.decodedToken.EmailConfirmed === 'True';
  }

  getPhoneNumberConfirmed(): boolean {
    return this.decodedToken.PhoneNumberConfirmed === 'True';
  }

  // Legacy, Created, Signed, Declined
  getClientAgreementStatus(): string {
    return this.decodedToken.ClientAgreementStatus as string;
  }

  canSeeHome(): boolean {
    const clientAgreementStatus = this.getClientAgreementStatus();
    return (
      clientAgreementStatus === 'Signed' || clientAgreementStatus === 'Legacy'
    );
  }

  getExpiryTime() {
    return this.decodedToken.exp;
  }

  isTokenExpired(): boolean {
    const expiryTime = Number(this.getExpiryTime());
    return expiryTime < Date.now() / 1000;
  }
}
